export default defineAppConfig({
  icon: {
    size: '1rem'
  },
  ui: {
    formGroup: {
      label: {
        base: 'block font-medium text-grey-blue'
      },
      help: 'text-xs mt-1',
      error: 'text-red-500 text-xs mt-1'
    },
    colorMode: {
      preference: 'light'
    },
    primary: 'trublue',
    notifications: {
      // Show toasts at the top right of the screen
      position: 'top-8 bottom-auto',
      default: {
        color: 'white',
        closeButton: {
          icon: 'i-mdi-close',
          color: 'white'
        }
      }
    },
    card: {
      base: ''
    },
    modal: {
      wrapper: 'z-[70]',
      base: 'relative text-left rtl:text-right overflow-visible mt-4 md:mt-8 w-full flex flex-col',
      container: 'min-h-0',
      overlay: {
        background: 'bg-black/60'
      }
    },
    input: {
      base: 'relative text-grey-blue placeholder:text-grey-blue block w-full disabled:cursor-not-allowed disabled:opacity-75 border-gray-300',
      size: {
        sm: 'text-xs'
      },
      padding: {
        sm: 'px-4 py-2'
      }
    },
    select: {
      base: 'relative block w-full disabled:cursor-not-allowed disabled:opacity-75 border-1 border-dark-grey',
      rounded: 'rounded-lg',
      padding: {
        xs: 'px-4 py-2'
      }
    },
    selectMenu: {
      input: 'w-[95%] rounded-lg text-sm m-2 text-grey-blue bg-white border-[1px] border-gray-200',
      default: {
        selectedIcon: 'i-mdi-check'
      },
      padding: 'px-2.5 py-2.5',
      option: {
        size: 'text-xs',
        selectedIcon: {
          base: 'h-4 w-4 text-gray-500'
        },
        active: 'bg-trublue-50 dark:bg-trublue-600'
      }
    },
    checkbox: {
      default: {
        color: 'trublue'
      },
      label: 'dark:text-white text-sm font-medium text-grey-blue mr-2'
    },
    avatar: {
      background: 'bg-trublue text-white',
      placeholder: 'font-medium leading-none text-white truncate',
      text: 'text-white',
      icon: {
        base: 'text-white',
        size: {
          sm: 'size-6'
        }
      }
    },
    dropdown: {
      container: 'z-40',
      width: 'min-w-64 max-w-xl',
      height: 'max-h-[60vh]',
      divide: 'divide-y divide-gray-200 dark:divide-gray-700',
      item: {
        base: 'text-sm group/item text-grey-blue gap-2',
        padding: 'py-2 px-1.5 my-0.5',
        active: 'bg-blue-7 dark:bg-trublue-600',
        disabled: 'text-dark-grey font-semibold opacity-100 uppercase text-xs'
      },
      icon: {
        base: 'mr-3'
      },
      padding: 'p-2'
    },
    alert: {
      title: 'font-normal',
      description: 'text-xs'
    },
    radio: {
      label: 'text-xs font-medium text-gray-500 mt-0.5'
    },
    slideover: {
      overlay: {
        base: 'top-12',
        background: 'bg-black/60'
      },
      width: 'max-w-xs md:max-w-sm'
    }
  }
})
