export const responseTypes: { [type: string]: string } = {
  json: 'application/json',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
}

export const getNumberValue = (item: string | number): number => {
  if (!item) {
    return 0
  }
  // make sure you strip string values of commas before parsing them :)
  return typeof item === 'string' ? parseFloat(item.replace(/[,%]/g, '')) : item
}

export const getStringValue = (item: string | number): string => (typeof item === 'number' ? item.toString() : item)

/**
 * Works out if an object has any properties or not
 * returns true if the object has no properties
 *
 * @param obj
 * @returns boolean
 */
export function objectIsEmpty(obj: object): boolean {
  for (const prop in obj) {
    if (Object.hasOwn(obj, prop)) {
      return false
    }
  }
  return true
}

export function getHumanRelativeDateTranslationString(date: string): Array<{
  string: string
  translationRequired: boolean
  options?: number | object
}> {
  const splitDate: Array<string> = date.split('|')
  if (splitDate[0] === 'previous') {
    if ((splitDate[1] === '1' && splitDate[2] === 'd') || (splitDate[1] === '24' && splitDate[2] === 'hour')) {
      return [{ string: 'filters.relativeDates.yesterday', translationRequired: true }]
    }

    if (splitDate[1] === '3' && splitDate[2] === 'month') {
      return [{ string: 'filters.relativeDates.lastQuarter', translationRequired: true }]
    }
  }

  return [
    { string: `filters.relativeDates.${splitDate[0]}`, translationRequired: true },
    { string: parseInt(splitDate[1]) > 1 ? ' ' + splitDate[1] + ' ' : ' ', translationRequired: false },
    { string: `filters.relativeDates.${splitDate[2]}`, translationRequired: true, options: parseInt(splitDate[1]) }
  ]
}

export function scrollToElement(id: string, offset = 0) {
  const element = document.getElementById(id)

  if (element) {
    const bodyRect = document.body.getBoundingClientRect().top
    const elementRect = element.getBoundingClientRect().top
    const elementPosition = elementRect - bodyRect
    const offsetPosition = elementPosition - offset

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth'
    })
  }
}

/**
 * Create a file with the specified file name and file type.
 * @param fileContent - The content of the file to be exported.
 * @param fileName - The name of the file to be downloaded.
 * @param [fileType=csv] - The file type you want to export.
 * @param [isBlob=false] - Is the fileContent already a blob.
 */
export function createExportFile(
  fileContent: any,
  fileName: string,
  fileType: string = 'csv',
  isBlob: boolean = false
) {
  const link = document.createElement('a')
  let blob = fileContent

  if (!isBlob) {
    blob = new Blob([fileContent])
  }

  let fullFileName = fileName
  if (!fileName.includes(`.${fileType}`)) {
    fullFileName = `${fileName}.${fileType}`
  }

  link.setAttribute('href', window.URL.createObjectURL(blob))
  link.setAttribute('download', fullFileName)
  document.body.appendChild(link)
  link.click()
  link.remove()
}

/**
 * Converts a hexadecimal color value to an RGB color value.
 * @param {String} hex - The hex value of the color you want to convert.
 * @param {Number} [opacity=1] - The opacity of the color.
 * @param {Boolean} [returnArray=false] - Whether to return an array or a string.
 * @returns {String} The function returns the rgba() string unless returnArray=true
 */
export function hexToRGB(hex: string, opacity: number = 1, returnArray: boolean = false): string | Array<number> {
  // @ts-expect-error - assume that the user always passes an actual hex string (because it's us)
  const result = hex
    .replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => '#' + r + r + g + g + b + b)
    .substring(1)
    .match(/.{2}/g)
    .map(x => parseInt(x, 16))

  if (returnArray) {
    return result
  }

  return result ? `rgba(${result[0]}, ${result[1]}, ${result[2]}, ${opacity})` : hex
}

/**
 * Get the contrasting text colour for the specified colour (hex code)
 * @param {string} hex
 * @returns {string} contrasting text colour
 */
export function getContrastTextColour(hex: string, darkColour: string = 'purple', lightColour: string = 'white') {
  // turn hex val to RGB value
  const rgb: Array<number> = hexToRGB(hex, 1, true) as Array<number>

  // calc to work out if it will match on black or white better
  // eslint-disable-next-line @stylistic/max-len
  // based on: https://stackoverflow.com/questions/11867545/change-text-color-based-on-brightness-of-the-covered-background-area
  const yiq = (rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000
  const isBright = yiq > 125

  return isBright ? darkColour : lightColour
}

/**
 * Decode a JWT token
 * shamelessly stolen from https://stackoverflow.com/questions/38552003/how-to-decode-jwt-token-in-javascript-without-using-a-library
 * @returns {string} JWT token
 */
export function parseJwt(token: string) {
  if (!token || !window) {
    throw new Error('parseJwt: Token Could Not Be Decoded')
  }

  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )

  return JSON.parse(jsonPayload)
}

// because atob doesn't support unicode characters
export function b64DecodeUnicode(str: string) {
  return decodeURIComponent(Array.prototype.map.call(atob(str), function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))
}
