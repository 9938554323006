<template>
  <HubButton
    v-show="isVisible"
    class="fixed bottom-0 right-0 z-50 m-4 border-2 border-white p-2 print:hidden"
    btn-style="hover:bg-trublue-600 bg-trublue text-white rounded-full "
    icon="i-mdi-page-first"
    icon-classes="w-8 h-8 rotate-90"
    icon-only
    @click="toTop"
  />
</template>

<script setup lang="ts">
const isVisible: Ref<boolean> = ref(false)

onMounted(() => {
  window.addEventListener('scroll', scrollHandler)
})

onBeforeUnmount(() => {
  window.removeEventListener('scroll', scrollHandler)
})

function scrollHandler() {
  isVisible.value = window.scrollY > 50
}

function toTop() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  })
}
</script>
