import { type HubSession } from '~/types'
import { responseTypes } from '~/utils/general-helpers'
import type { ServerDataResponse } from '~/types/component'

export default function hubFetch<TResponse = ServerDataResponse>(
  url: string,
  options: object = {},
  responseType: 'json' | 'xlsx' = 'json'
) {
  const runtimeConfig = useRuntimeConfig()
  const router = useRouter()
  const { data: authData, status } = useAuth()
  const userSession = authData.value as HubSession

  if (status.value === 'unauthenticated')
    throw new Error('User is unauthenticated', {
      cause: {
        statusCode: 401,
        statusMessage: 'Unauthenticated',
        message: 'unauthenticated'
      }
    })

  return $fetch<TResponse>(url, {
    baseURL: runtimeConfig.public.apiBase,
    headers: {
      Authorization: `Bearer ${userSession.accessToken}`,
      Accept: responseTypes[responseType]
    },
    onResponseError({ response }) {
      // if the user is authenticated but the response is 401, it means the token is expired, so we should sign out the user
      console.warn('onResponseError :>> ', response)
      if (response.status === 401) router.push('/login?signOut=true')
    },
    ...options
  })
}
