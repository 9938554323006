<template>
  <div
    class="grid min-h-screen place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8"
  >
    <div class="flex flex-col items-center justify-center text-center">
      <img
        src="~/assets/img/component-error.svg"
        style="max-height: 400px"
      >

      <h1 class="mt-10 text-lg font-medium text-dark-purple">
        {{ customMessage || errorTitle }}
      </h1>
      <p
        v-if="errorMessage"
        class="mt-5 font-medium text-grey-blue"
      >
        {{ errorMessage }}
      </p>
      <HubButton
        class="mt-6"
        :label="$t(`errors.generic.buttonText`)"
        @click="handleError"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { NuxtError } from '#app'

const { t } = useI18n()
const props = defineProps<{
  error?: NuxtError
  statusCode?: number
  customMessage?: string
}>()

const errorTitle: Ref<string> = ref('')
const errorMessage: Ref<string | undefined> = ref()

watch(
  () => props,
  () => {
    errorTitle.value = props.customMessage || t('errors.500.title')
    errorMessage.value = undefined

    if (props.error && props.statusCode) {
      errorTitle.value = props.customMessage
      || props.error.message
      || props.error.statusMessage
      || t('errors.500.title')

      if ([500, 404, 403].includes(props.statusCode as number)) {
        errorTitle.value = t(`errors.${props.statusCode}.title`)
        errorMessage.value = t(`errors.${props.statusCode}.message`)
      }
    }
  },
  {
    immediate: true,
    deep: true
  }
)

function handleError() {
  clearError({ redirect: '/' })
}
</script>
