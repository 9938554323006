import { defineNuxtPlugin } from '#app/nuxt'
import { LazyIcon, LazyPrimeCalendar, LazyPrimeChips, LazyPrimeDataTable, LazyPrimeTabView, LazyPrimeTabPanel } from '#components'
const lazyGlobalComponents = [
  ["Icon", LazyIcon],
["PrimeCalendar", LazyPrimeCalendar],
["PrimeChips", LazyPrimeChips],
["PrimeDataTable", LazyPrimeDataTable],
["PrimeTabView", LazyPrimeTabView],
["PrimeTabPanel", LazyPrimeTabPanel],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
