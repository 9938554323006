import { httpClientIntegration, captureConsoleIntegration } from '@sentry/integrations'
import {
  addBreadcrumb,
  browserTracingIntegration,
  captureException,
  init,
  replayIntegration,
  setContext,
  setTag,
  setUser
} from '@sentry/vue'

export default defineNuxtPlugin(nuxtApp => {
  const config = useRuntimeConfig()

  init({
    enabled: config.public.sentryEnvironment !== 'local',
    app: nuxtApp.vueApp,
    autoSessionTracking: true,
    debug: config.public.sentryEnvironment !== 'production',
    dsn: config.public.sentryDSN,
    environment: config.public.sentryEnvironment,
    release: config.public.dockerImageTag || 'unknown',

    integrations: [
      replayIntegration(),
      browserTracingIntegration(),
      httpClientIntegration(),
      captureConsoleIntegration({
        // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
        levels: ['error']
      })
    ],
    sendDefaultPii: true,
    trackComponents: true,
    hooks: ['activate', 'create', 'destroy', 'mount', 'update'],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.2,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1
  })

  return {
    provide: {
      sentrySetContext: setContext,
      sentrySetUser: setUser,
      sentrySetTag: setTag,
      sentryAddBreadcrumb: addBreadcrumb,
      sentryCaptureException: captureException
    }
  }
})
