<template>
  <main>
    <HubError
      :error="error"
      :status-code="error?.statusCode"
    />
  </main>
</template>

<script setup lang="ts">
const error = useError()
</script>
